@charset "UTF-8";

// メニュー上部のロゴ表示
.nav-in-logo {
  display: none;
  padding: 2.5em 1em;

  & > a {
    padding: 0;

    & > img {
      width: 100%;
    }
  }

  @include media {
    display: block;
  }
}

// nav 内のバナーエリア
.banner-area {
  width: 200px;
  margin: 2em auto;

  & li {
    margin-bottom: 1em;
    text-align: center;
  }
}
