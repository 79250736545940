@charset "UTF-8";

.block {
	border: 1px solid $base-color;
  &.double{
    border: 2px solid $base-color;
  }

  &.center {
    text-align: center;
  }

  &.second {
    border-color: $second-color;
    //color: $second-color;
    &.double{
      border: 2px solid $second-color;
    }
  }
  &.third {
    border-color: $third-color;
    //color: $third-color;
    &.double{
      border: 2px solid $third-color;
    }
  }
  &.fourth {
    border-color: $fourth-color;
    //color: $fourth-color;
    &.double{
      border: 2px solid $fourth-color;
    }
  }
  &.five {
    border-color: $five-color;
    //color: $five-color;
    &.double{
      border: 2px solid $five-color;
    }
  }
}

.block.height {
  height: 100%;
}

.block-header {
  background: $base-color;
  //border-radius: 3px 3px 0 0;
  color: #fff;
  font-size: inherit;
  font-weight: bold;
  padding: .3em 1em;

  .second & {
    background: $second-color;
  }
  .third & {
    background: $third-color;
  }
  .fourth & {
    background: $fourth-color;
  }
  .five & {
    background: $five-color;
  }
}

.block-body {
  color: inherit;
  padding: 1em;

  & .grid { margin: -$base-gutter; }
}
