@charset "UTF-8";

@for $i from 1 through 25 {
  .mt#{$i * 4} {
    margin-top: #{($i * 4) / 10}em;
    @include media(large) {
      margin-top: #{($i * 4) / 10 * 1.5}em; }
  }
  .mb#{$i * 4} {
    margin-bottom: #{($i * 4) / 10}em ;
    @include media(large) {
      margin-bottom: #{($i * 4) / 10 * 1.5}em; }
  }
  .mr#{$i * 4} {
    margin-right: #{($i * 4) / 10}em;
    @include media(large) {
      margin-right: #{($i * 4) / 10 * 1.5}em; }
  }
  .ml#{$i * 4} {
    margin-left: #{($i * 4) / 10}em;
    @include media(large) {
      margin-left: #{($i * 4) / 10 * 1.5}em; }
  }
  .pt#{$i * 4} {
    padding-top: #{($i * 4) / 10}em;
    @include media(large) {
      padding-top: #{($i * 4) / 10 * 1.5}em; }
  }
  .pb#{$i * 4} {
    padding-bottom: #{($i * 4) / 10}em;
    @include media(large) {
      padding-bottom: #{($i * 4) / 10 * 1.5}em; }
  }
  .pr#{$i * 4} {
    padding-right: #{($i * 4) / 10}em;
    @include media(large) {
      padding-right: #{($i * 4) / 10 * 1.5}em; }
  }
  .pl#{$i * 4} {
    padding-left: #{($i * 4) / 10}em;
    @include media(large) {
      padding-left: #{($i * 4) / 10 * 1.5em}; }
  }
}

@for $i from 1 through 20 {
  .w#{$i * 5}per {
    width: #{$i * 5%};
  }
}

.mt00 {
  margin-top: 0 !important; }
.mr00 {
  margin-right: 0 !important; }
.mb00 {
  margin-bottom: 0 !important; }
.ml00 {
  margin-left: 0 !important; }
.pt00 {
  padding-top: 0 !important; }
.pr00 {
  padding-right: 0 !important; }
.pb00 {
  padding-bottom: 0 !important; }
.pl00 {
  padding-left: 0 !important; }

