@charset "UTF-8";

// メインイメージ
.main-visual {
  background: url('/images/visual.jpg') 0 0 no-repeat;
  background-size: cover;
  flex: none;
  padding-bottom: calc(200%*640/1920);
  position: relative;
  @include media(medium) {
    padding-bottom: calc(100%*640/1920);
  }
  & .main-text {
    color: #fff;
    font-size: 1.5em;
    line-height: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    text-shadow: 0px 0px 8px #333;
    width: 100%;
    padding: 1em;
    @include media(medium) {
      font-size: 2em;
    }
  }
}

//　スライダー
.swiper-container {
  width: 100%;
  height: auto;
  //　メインテキストありの場合
  & .main-text {
    color: #fff;
    font-size: 2em;
    line-height: 1.5;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    text-shadow: 0px 0px 8px #333;
    z-index: 999;
  }
}
//　ドットの色変更
.swiper-pagination-bullet-active {
  background: #EF810F !important;
}
//　スマホでは画像をトリミング
.swiper-slide {
  //　高さ計算
  padding-bottom: calc(180%*420/1920);
  @include media(medium) {
  padding-bottom: 0;
  }
}
//　位置調整
.swiper-wrapper > div > img{
    max-width: none;
    width: 180% !important;
    right: -4%;
    position: absolute;
    @include media(medium) {
      max-width: 100%;
      width: 100%;
      position: static;
    }
}
//　スマホではドットなし
.swiper-pagination{
  display: none;
  @include media(medium) {
    display: block;
  }
}

// トピックス
/*
.topics {
  & a {
    & img {
      transition: filter .3s;
    }
    &:hover img {
      filter: drop-shadow(0px 0px 6px #ccc);
      //box-shadow:0px 0px 9px 3px #ccc;
    }
  }
}
*/

// facebook
.fb-wrap{
  margin: 0 auto;
  padding-top: 1rem;
  width: 50rem;
  max-width: 100%;
}

.fb_iframe_widget,
.fb_iframe_widget span,
.fb_iframe_widget iframe[style]{
    width: 100% !important;
}

.border{
  border: solid 1px #ccc;
}

.msg{
  font-size: 1.2em;
  font-weight: bold;
  line-height: 2;
}

.info-title{
    background: $color-red;
    color: #fff;
    padding: 0.125em 0.75em;
    display: inline-block;
    margin-bottom: 1em;
    border-radius: 2px;
    font-size: 1.125em;
    span{
      display: block;
    }
}

.bnr-parent{
  position: relative;
  display: block;
  box-sizing: border-box;
  & .bnr-date{
    position: absolute;
    bottom: 0;
    background: #009999;
    padding: 0.25em 0.5em;
    width: 100%;
    display: block;
    color: #fff;
    text-align: center;
    font-weight: 700;
  }
}

.topics a[href$=".pdf"]::before{
  display: none;
}

.noicon a[href$=".pdf"]::before{
  display: none;
}

.bg-box{
  background: $third-color;
  padding: 1em 1.5em;
  border-radius: 10px;
}

.sub-menu li a[href$=".pdf"]::before{
  display: none;
}

.map{
  height: 320px;
}
@include media(medium) {
  .map{
    height: 540px;
  }
}

/*
.top-bnr-content{
  border: solid 1px #ccc;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em;
  border-radius: 5px;
  transition: 0.25s;
  height: 100%;
  img{
    width: 64px;
    height: 64px;
    @include media(medium) {
      width: 80px;
      height: 80px;
    }
  }
  .top-bnr-roman{
    padding-top: .125em;
    white-space: nowrap;
    font-size: 0.6em;
    color: rgba($gray-light, 0.5);
    font-weight: bold;
    @include media(medium) {
      font-size: 0.8em;
    }
  }
  .top-bnr-txt{
    padding-top: .5em;
    font-size: 0.8em;
    line-height: 1.5;
    text-align: center;
    @include media(medium) {
      font-size: 1em;
    }
    br{
      display: none;
      @include media(medium) {
        display: block;
      }
    }
  }
  &:hover{
    text-decoration: none;
    @include media(medium) {
      background: rgba($base-color, 0.125);
      border: solid 1px $base-color;
    }
  }
}
*/

.visual img{
  width: 100%;
}

.notice-wrapper{
  border: solid 2px $color-red;
  background: rgba($color-red, 0.1);
  padding: 1em;
  .notice-heading{
    color: $color-red;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.25em;
    margin-bottom: 0.5em;
    line-height: 1.5;
    font-weight: 500;
    &::before{
      content: '\f05a';
      font-family: fontawesome;
      font-size: 1.5em;
      margin-right: 0.5em;
    }
  }
  .notice-button{
    background-color: $color-red !important;
    &:hover{
      background-color: darken($color-red, 10%) !important;
    }
  }

  &.blue{
    border: solid 2px #546de5;
    background: rgba(#546de5, 0.1);
    .notice-heading{
      color: #546de5;
    }
    .notice-button{
      background-color: #546de5 !important;
      &:hover{
        background-color: darken(#546de5, 10%) !important;
      }
    }
  }

  &.base{
    border: solid 2px $base-color;
    background: rgba($base-color, 0.1);
    .notice-heading{
      color: $base-color;
    }
    .notice-button{
      background-color: $base-color !important;
      &:hover{
        background-color: darken($base-color, 10%) !important;
      }
    }
  }

  &.second{
    border: solid 2px $second-color;
    background: rgba($second-color, 0.1);
    .notice-heading{
      color: $second-color;
    }
    .notice-button{
      background-color: $second-color !important;
      &:hover{
        background-color: darken($second-color, 10%) !important;
      }
    }
  }

}
