@charset "UTF-8";


.accordion-menu {
  background: $base-color;
  list-style: none;
  margin: 0;
  padding: 0;

  & > li {
    border-bottom: 1px solid darken($base-color,10%);
    cursor: pointer;
    color: #fff;
    position: relative;
    
    & .toggle {
      padding: 1rem 0 1rem 1.2rem;
      
      &:after {
        position: absolute;
        top: 1.8rem;
        right: 1.6rem;
        content: '';
        display: inline-block;
        width: 10px;
        height: 10px;
        border-right: 1px solid #fff;
        border-bottom: 1px solid #fff;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        transition: transform 0.8s; }
      
      &.active:after {
        position: absolute;
        top: 1.8rem;
        right: 1.6rem;
        content: '';
        display: inline-block;
        width: 10px;
        height: 10px;
        border-right: 1px solid #fff;
        border-bottom: 1px solid #fff;
        -webkit-transform: rotate(225deg);
        -ms-transform: rotate(225deg);
        transform: rotate(225deg); }
      }

    & ul {
      background: #fcfcfc;
      list-style: none;
      margin: 0;
      padding: 0;
      
      & li {
        border-bottom: 1px solid #cdcdcd;
        &:last-child {
          border-bottom: none;
        }
        
        & a {
          display: block;
          font-size: 1.4rem;
          padding: 1rem 0 1rem 1rem;
          
          &:before {
            content: '';
            display: inline-block;
            width: 8px;
            height: 8px;
            margin: 0 0.6rem 0.2rem 0;
            border-right: 2px solid $base-color;
            border-top: 2px solid $base-color;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }
      }
    }
  }
}