@charset "UTF-8";

// Setting

$root: '/';//スラッシュ入れる
$ssl: 'https://sv124.wadax.ne.jp/~cc-yashio-jp/';//スラッシュ入れる

// Breakpoints
$breakpoints: (
  'medium': 'screen and (min-width: 48em)',
  'large':  'screen and (min-width: 64em)',
) !default;

// Width
$width-medium: 76.8rem; // 768px
$width-large:  102.4rem; // 1024px
$width-menu: 290px; // menu の幅

// 2 column
$width-main-column: 100rem;
$width-side-column: 26.4rem;

// headerの高さ
$height-header: 70px;

// font family
$font-family: 'Noto Sans JP', "YakuHanJPs", -apple-system, BlinkMacSystemFont, Helvetica, Arial, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
//$font-family: "YakuHanJPs", Helvetica, Arial, "游ゴシック体", "Yu Gothic", YuGothic,  "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
//$font-family: "YakuHanJPs", Arial, "メイリオ", Meiryo, sans-serif;
// ベースの文字サイズ
$small-font-size:  1.4rem; // モバイル用フォントサイズ
$medium-font-size: 1.6rem; // タブレット用フォントサイズ
$large-font-size:  1.6rem; // PC用フォントサイズ
// Base line-height
$base-line-height: 1 !default;
$base-line-height-small:  $base-line-height * 1.6;
$base-line-height-medium: $base-line-height * 1.8;
$base-line-height-large:  $base-line-height * 2;
// 見出し用フォントサイズ モバイルベース
$h1-font-size: 2.0em;
$h2-font-size: 1.8em;
$h3-font-size: 1.3em;
$h4-font-size: 1.2em;
$h5-font-size: 1.0em;
$h6-font-size: 0.8em;
$h-scale-medium: 1.15;
$h-scale-large:  1.15;

// カラム数
$grid-columns: 12 !default;

// margin & padding
$base-gutter: 1rem !default; // レイアウト ベースのmargin
$base-padding: 1rem !default; // レイアウト ベースの Padding

// Color
$gray-dark:     #333333 !default;
$gray:          #55595c !default;
$gray-light:    #818a91 !default;
$gray-lighter:  #DFE2E4 !default;
$gray-lightest: #eceeef !default;

$base-color:    #61bd64 !default;
$second-color:  #EF8C0F !default;
//$base-color:    #EF810F !default;
//$second-color:  #44A047 !default;
$third-color:   #fff6d7 !default;//地の色
$fourth-color:  #f5ebd7 !default;//すこしくらい
$five-color:    #fff9e8 !default;//すこしあかるい

$color-red:    #e05060 !default; // 金赤
$color-yellow: #fff000 !default; // Y100

// Settings for the `<body>` element.
$body-bg:    #fff !default;
$body-color: $gray-dark !default;

// Style anchor elements.
$link-color:                 $second-color !default;
$link-decoration:            none !default;
$link-hover-color:           darken($link-color, 15%) !default;
$link-hover-decoration:      underline !default;

// Tableのボーダーの色
$table-border-color: #ccc !default;
