@charset "UTF-8";

footer {
  flex: none;
  margin-top: 3em;
  width: 100%;
  min-width: 320px;
  z-index: 9;
}

.footer-link {
  background: rgba($third-color, 0.75);
  padding: 1rem 0;
  font-size: 1em;
  & ul {
    @include make-flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    @include media(large) {
      flex-direction: row;
    }
    & li {
      &:not(:first-child) {
        @include media(large) {
          margin-left: 2em;
        }
      }
      & a {
        color: $gray-dark;
        transition: color .2s;
        padding: 0.5em;
        display: block;
        &:hover {
          opacity: 0.5;
          text-decoration: none;
        }
      }
    }
  }
}

.footer-contents {
  padding: 10px;
  margin: 0 auto 3em auto;
  width: 100%;
  min-width: 320px;
  @include media(large) {
    //min-width: 980px;
    //max-width: 1200px;
    max-width: 980px;
  }
}

.foot-info{
  background: rgba($third-color, 0.75);
  padding: 1em;
  h3{
    background: $base-color;
    border-radius: 5px;
    color: #fff;
    font-size: 1.3em;
    padding: 0.4em 1em;
    z-index: -4;
  }
  .border{
    border: solid 1px $base-color;
    padding: 1em;
    background: #fff;
  }
  p.disc{
    font-size: 0.8em;
    line-height: 1.5;
  }
}

.copyright {
  background: $base-color;
  color: #fff;
  display: block;
  text-align: center;
  padding: 0.5em 0;
  width: 100%;
  font-size: 0.8em;
}
