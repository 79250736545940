@charset "UTF-8";
/* Layout */

/* 横幅100%用
---------- ---------- ---------- ---------- ---------- */
.wrapper {
  width: 100%;
  min-width: 320px;
  @include media(large) {
    min-width: $width-large;
  }
}

/* Breakpointごとのメイン横幅
---------- ---------- ---------- ---------- ---------- */
.container {
  padding: 0 $base-padding;
  margin: 0 auto;
  width: 100%;

  @include media {
    min-width: 980px;
  }
}
/*.container {
  padding: 0 $base-padding;
  margin: 0 auto;
  width: 100%;
  //max-width: 100%;
  @include media(medium) {
    padding: 0 $base-padding;
    max-width: $width-medium;
  }
  @include media(large) {
    padding: 0 $base-padding;
    max-width: $width-large;
    //min-width: $breakpoints-large; //$width-large;
    }
}*/

/* 全体の大枠
---------- ---------- ---------- ---------- ---------- */
.general-wrapper {
  position: relative;
  width: 100%;
  @include media {
    //min-width: 980px;
  }
}

main {
  margin-top: $height-header * .685;
  flex: 1 0 auto;
  overflow: hidden;
  min-height: 100vh;
  @include media {
    margin-top: 0;
  }
}

.general-layout {
  @include make-flex;
  flex-flow: column nowrap;
  align-content: space-between;
  //height: 100%;
  min-height: 100vh;
}

.main-contents {
  padding: 0 2rem;
  margin: 0 auto;
  width: 100%;
  min-width: 320px;
  overflow: hidden;
  @include media(medium) {
    //min-width: 980px;
    max-width: 76.8rem;
    //width: 980px;
  }
  @include media(large) {
    //min-width: 980px;
    padding: 0 1rem;
    max-width: 98rem;
    //width: 980px;
  }
}

section {
  margin-top: 3rem;
  @include media {
    margin-top: 5rem;
  }
}

.fa{
  margin: 0 0.25em;
}

.arrow-down{
text-align: center;
  & span{
  padding: 0.5em 1em;
  border: solid 2px #9AC21F;
  /*border-left: solid 12px #9AC21F;
  border-right: solid 4px #9AC21F;*/
  display: block;
  }
  & li+li::before{
    content:"";
    background-image: url(/src/img/arrow-down.svg);
    width: 4rem;
    height: 4rem;
    display: block;
    background-size: contain;
    vertical-align: middle;
    margin: 0.5em auto;
  }
}
