@charset "UTF-8";

.bg-base {
  background-color: $base-color;
  
  &.light {
    background-color: lighten($base-color, 30%);
  }
}

.bg-second {
  background-color: $second-color;
  
  &.light {
    background-color: lighten($second-color, 30%);
  }
}

.bg-third {
  background-color: $third-color;
  
  &.light {
    background-color: lighten($third-color, 30%);
  }
}

.bg-fourth {
  background-color: $fourth-color;
  
  &.light {
    background-color: lighten($fourth-color, 30%);
  }
}

.bg-five {
  background-color: $five-color;
  
  &.light {
    background-color: lighten($five-color, 30%);
  }
}

.bg-white { background-color: #fff; }