@charset "UTF-8";

button {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  padding: 0;
  border: 0;
  line-height: 1;
  &:focus {
    outline: 1px dotted;
    outline: 0px auto -webkit-focus-ring-color;
  }
}

.button {
  background-color: $base-color;
  border-radius: 2px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  //font-weight: bold;
  text-align: center;
  line-height: 1 !important;
  -webkit-appearance: none;
  -webkit-transition: background-color 0.25s ease-out, color 0.25s ease-out;
          transition: background-color 0.25s ease-out, color 0.25s ease-out;
  vertical-align: middle;
  padding: .8em 1em;
  margin: 0.4rem 0;

  & span {
    position: relative;
    // vertical-align: middle;
    &::after {
      content: '';
      background: url('/images/icon/icon-arrow-btn.svg') no-repeat;
      background-size: contain;
      display: inline-block;
      width: 1em;
      height: 1em;
      position: relative;
      top: 0.1em;
      right: -0.5rem;
      padding-right: 1.4em;
    }
  }

  &:hover {
    background-color: darken($base-color,15%);
    color: #fff;
    text-decoration: none; }
  & a:visited .button {
    color: inherit; }
  // EXPANNDED
  &.expanded {
    display: block;
    width: 100%;
    margin-left: 0;
    margin-right: 0; }
  // サイズ
  &.xsmall {
    font-size: 0.6em;
    padding: 0.7em .8em; }
  &.small {
    font-size: 0.8em;
    padding: 0.7em 0.8em ; }
  &.large {
    font-size: 1.1em;
    padding: 0.6em 1em;
  }
  // カラー
  &.second {
    background-color: $second-color;
    &:hover {
    background-color: darken($second-color,15%); }
  }
  &.third {
    background-color: $third-color;
    &:hover {
    background-color: darken($third-color,15%); }
  }
  &.fourth {
    background-color: $fourth-color;
    &:hover {
    background-color: darken($fourth-color,15%); }
  }
  &.five {
    background-color: $five-color;
    &:hover {
    background-color: darken($five-color,15%); }
  }
  &.orgn-gray {
    color: #333;
    background-color: #e8e8e8;
    border-left: solid 5px $base-color;
    &:hover {
    background-color: darken(#e8e8e8,10%); }
    & span {
      &::after {
        content: '';
        background: url('/images/icon/icon-arrow-btn-base.svg') no-repeat;
        background-size: contain;
      }
    }
  }

  // アウトライン
  &.outline {
    background-color: #fff;
    border: 1px solid $base-color;
    color: $base-color;
    text-shadow: none;

    & span {
      &::after {
        content: '';
        background: url('/images/icon/icon-arrow-btn-gray.svg') no-repeat;
        background-size: contain;
      }
    }
    &:hover {
      background-color: lighten($base-color,40%); }
      // アウトライン カラー
      &.second {
        background-color: #fff;
        border: 1px solid $second-color;
        color: $second-color;
        &:hover {
          background-color: lighten($second-color,35%); }
        }
      &.third {
        background-color: #fff;
        border: 1px solid $third-color;
        color: $third-color;
        &:hover {
          background-color: lighten($third-color,35%); }
        }
      &.fourth {
        background-color: #fff;
        border: 1px solid $fourth-color;
        color: $fourth-color;
        &:hover {
          background-color: lighten($fourth-color,35%); }
        }
      &.five {
        background-color: #fff;
        border: 1px solid $five-color;
        color: $five-color;
        &:hover {
          background-color: lighten($five-color,35%); }
        }
      &.ghost {
        background-color: transparent;
        border: 1px solid rgba(#fff,0.7);
        color: rgba(#fff,0.7);
        &:hover {
          background-color: rgba(#000, 0.3); }
      }
  }
}

form button.button-block {
  display: block;
  width: 100%;
  @include media(large) {
  form button.button-block {
    margin: 2rem auto;
    width: 60%; }
  }
}
