@charset "UTF-8";

// メインイメージ
.sub-visual {
  background: url('/images/visual.jpg') 0 0 no-repeat;
  background-size: 100% auto;
  background-position: top;
  flex: none;
  padding-bottom: calc(100%*300/1920);
  position: relative;
  @include media {
    padding-bottom: calc(100%*200/1920);
  }
  &::before{
    background-color: darken(rgba(#333,0.25), 40%);
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  & .sub-text {
    color: #fff;
    font-size: 1.6em;
    line-height: 1.5;
    position: absolute;
    top: 50%;
    left: 0;
    padding-left: 1rem;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    text-align: left;
    text-shadow: 0px 0px 8px #333;
    font-weight: 500;
    @include media {
      font-size: 1.8em;
      padding-left: 3rem;
    }
  }
}

// メインイメージ
.sub-visual-recruit {
  background: url('/images/visual.jpg') 0 0 no-repeat;
  background-size: 100% auto;
  background-position: top;
  flex: none;
  padding-bottom: calc(100%*300/1920);
  position: relative;
  @include media {
    padding-bottom: calc(100%*200/1920);
  }
  &::before{
    background-color: darken(rgba(#333,0.25), 40%);
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  & .sub-text {
    color: #fff;
    font-size: 1.6em;
    line-height: 1.5;
    position: absolute;
    top: 50%;
    left: 0;
    padding-left: 1rem;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    text-align: left;
    text-shadow: 0px 0px 8px #333;
    font-weight: 500;
    @include media {
      font-size: 1.8em;
      padding-left: 3rem;
    }
  }
}

// 関連リンクリスト
ul.sub-nav {
  margin: 10px 0 0 0;
  padding: 0;
  list-style: none; }

ul.sub-nav li > a {
  color: $gray-dark;
  display: block;
  text-decoration: none;
  padding: 1.2rem; }
  .sub-nav li > a:hover {
    background: lighten($base-color, 40%); }

// GoogleMap
#map_canvas {
  width: 100%;
  height: 320px; }
@include media {
  #map_canvas {
    height: 540px; } }

//magazine
.magazine a{
  text-align: center;
  &[href$=".pdf"]::before{
    display: none;
  }
  & > figure > img{
    border: solid 1px #ccc;
    transition: 0.25s;
    &:hover{
      opacity: 0.5;

    }
  }
}
