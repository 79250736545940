@charset "UTF-8";

.xlarge {
  font-size: 125%;
  font-weight:normal;
  line-height: $base-line-height*1.6;
}

.large {
  font-size: 112.5%;
  font-weight:normal;
  line-height: $base-line-height*1.6;
}

.middle {
  font-size: 87.5%;
  font-weight: normal;
  font-weight:normal;
  line-height: $base-line-height*1.6;
}

.small {
  font-size: 75%;
  font-weight:normal;
  line-height: $base-line-height*1.6;
  &.small-block {
    display: inline;
    @include media(large) {
      display: block;
      margin-top: 1em;
    }
  }
}

.xsmall {
  font-size: 62.5%;
  font-weight: normal;
  line-height: $base-line-height*1.6;
}

