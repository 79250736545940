@charset "UTF-8";

.list-tile {
  @include make-flex;
  @include flex-flow;
  margin: 0 -1rem;

  & li {
    padding: 1rem;
  }

  @for $i from 1 through 12 {
    &.sm-col-#{$i} {
      & li {
        @include make-grid(100% / $i);
      }
    }

    @include media(medium) {
      &.md-col-#{$i} {
      & li {
        @include make-grid(100% / $i);
      }
    }
  }

    @include media(large) {
      &.lg-col-#{$i} {
      & li {
        @include make-grid(100% / $i);
      }
    }
    }
  }
}

