@charset "UTF-8";
/* label */

.label {
  background-color: $base-color;
  border-radius: 0px;
  display: inline-block;
  //margin-right: 1em;
  padding: 0em 0.4em;
  font-size: 85.714285%;
  //font-weight: bold;
  //line-height: 1;
  color: #fff;
  text-align: center;
  //white-space: nowrap;
  position: relative;
  //top: -2.0px;

  &.small {
    font-size: 68.75%;
  }

  &.large {
    font-size: 100%;
    padding: 0.075em 0.6em;
  }

  &.second {
    background-color: $second-color;
  }
  &.third {
    background-color: $third-color;
  }
  &.fourth {
    background-color: $fourth-color;
  }
  &.five {
    background-color: $five-color;
  }
  &.red{
    background-color: $color-red;
  }

  &.outline {
    background: #fff;
    border: 1px solid $base-color;
    color: $base-color;

    &.second {
      border-color: $second-color;
      color: $second-color; }
    &.third {
      border-color: $third-color;
      color: $third-color; }
    &.fourth {
      border-color: $fourth-color;
      color: $fourth-color; }
    &.five {
      border-color: $five-color;
      color: $five-color; }
  }

  &.extended {
    display: block;
  }
}

span.label {
  margin-right: 0.5em;
}
