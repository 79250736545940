@charset "UTF-8";

/* ---------- 横列 */
.grid {
  max-width: $width-large;
  margin: 0 (-$base-gutter / 2);
  @include make-flex;
  @include flex-flow;

  @include media(large) {
    max-width: $width-large;
		margin: 0 (-$base-gutter);
  }

  &.gapless {
    margin: ($base-gutter / 2) 0 0;
    & + .gapless {
      margin: 1rem 0 ;
      @include media(large) {
        margin: 2rem 0;
      }
    }

    & .grid {

      & .grid {
        margin: (-$base-gutter / 2);

        @include media(large) {
          margin: ($base-gutter / 2) (-$base-gutter);
        }
      }
    }

    @include media(large) {
      margin: $base-gutter 0;
    }
  }
}

 /* ---------- 縦列 */
.grid-file {
  max-width: $width-large;
  margin: 0 (-$base-gutter);
  @include make-flex;
  -webkit-flex-flow: column wrap;
      -ms-flex-flow: column wrap;
          flex-flow: column wrap;

  @include media(large) {
    max-width: $width-large;
		margin: 0 (-$base-gutter);
  }
}

.grid .grid {
  margin: (-$base-gutter / 2);

  &.gapless {
    margin: 0;
  }

  @include media(large) {
    margin: 0 (-$base-gutter);
  }
}

.column {
  padding: $base-padding / 2;
    -webkit-flex: 1 1 0;
	      -ms-flex: 1 1 0;
		        flex: 1;

  @include media(large) {
    padding: $base-padding;
    width: auto;
  }
}

.raw-column {
  text-align: center;
  padding: $base-padding;
  width: 100%;

  @include media(large) {
    padding: $base-padding;
    width: auto;
  }
}

.column > .column {
	padding: $base-padding 0;
  margin: (-$base-gutter) 0 $base-gutter;
  @include media(large) {
    padding: $base-padding 0;
    margin: (-$base-gutter) 0 $base-gutter;
  }
}

.grid-file > .column {
			-webkit-flex: 0 1 auto;
					-ms-flex: 0 1 auto;
							flex: 0 1 auto;
}
.grid-file > .column:first-child {
	margin-top: (-$base-gutter);
}

.column > .grid {
  margin: (-$base-gutter / 2);

  & + .grid {
    margin: ($base-gutter / 2) (-$base-gutter / 2);

    .gapless & {
      margin: 0;
    }
  }

  @include media(large) {
    margin: -$base-gutter;

    & + .grid {
      margin: $base-gutter (-$base-gutter);
    }
  }
}
.column > .grid.gapless {
  margin: ($base-gutter / 2) 0;

  & + .grid.gapless {
    margin: (-$base-gutter / 2) 0 0;
  }

  @include media(large) {
    margin: $base-gutter 0;

    & + .grid.gapless {
      margin: (-$base-gutter) 0 0;
    }
  }
}

// ++++++++++++++++++++++++++++++ Smart Phone 767px以下*/
@for $i from 1 through $grid-columns {
	.small-#{$i} {
		@include make-grid(100%/($grid-columns / $i));
	}
	.small-#{$i}-center {
		@include make-grid(100%/($grid-columns / $i));
		margin: 0 auto;
	}
	.small-offset-#{$i} {
		margin-left:(100%/($grid-columns / $i));
	}
}

@include media(medium) {
  @for $i from 1 through $grid-columns {
    .medium-#{$i} {
      @include make-grid(100%/($grid-columns / $i));
    }
    .medium-#{$i}-center {
      @include make-grid(100%/($grid-columns / $i));
      margin: 0 auto;
    }
    .medium-offset-#{$i} {
      margin-left:(100%/($grid-columns / $i));
    }
  }
}

@include media(large) {
	@for $i from 1 through $grid-columns {
		.large-#{$i} {
			@include make-grid(100%/($grid-columns / $i));
		}
		.large-#{$i}-center {
			@include make-grid(100%/($grid-columns / $i));
			margin: 0 auto;
		}
		.large-offset-#{$i} {
			margin-left:(100%/($grid-columns / $i));
		}
	}
}

// ++++++++++++++++++++++++++++++ 12 column */
	@for $i from 1 through $grid-columns {
		.column-#{$i} {
			@include make-grid(100%/($grid-columns / $i));
		}
	}
