@charset "UTF-8";

// Pagetop

.pagetop {
  display: none;
  position: fixed;
  bottom: 1rem;
  right: 1rem;

  @include media(large) {
    bottom: 6rem;
    right: 3rem;
  }

  & a {
    display: block;
    background-color: rgba($second-color,0.9);
    text-align: center;
    color: #fff;
    //line-height: 60px;
    font-size: 14px;
    text-decoration: none;
    border-radius: 50%;
    transition: background-color 0.25s ease-out, color 0.25s ease-out;
    width: 44px;
    height: 44px;
    padding: 0.4rem;
    @include media(medium) {
      width: 50px;
      height: 50px;
    }
    @include media(large) {
      width: 60px;
      height: 60px;
    }
    &:after {
      content: '';
      display: inline-block;
      width: 18px;
      height: 18px;
      border-right: 3px solid #fff;
      border-top: 3px solid #fff;
      -webkit-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
              transform: rotate(-45deg);
      margin-top: 0.875em;
      transition: transform .3s ease-out;
      @include media(medium) {
        width: 20px;
        height: 20px;
        margin-top: 1em;
      }
      @include media(large) {
        width: 24px;
        height: 24px;
        margin-top: 1.325em;
      }
    }
  }
}
