@charset "UTF-8";

/* Font Set
---------- ---------- ---------- ---------- ---------- */

p {
  font-size: inherit;
  line-height: inherit;
  margin-bottom: 1em;
  text-rendering: optimizeLegibility;
}

em,
i {
  font-style: italic;
  line-height: inherit;
}

strong,
b {
  font-weight: bold;
  line-height: inherit;
}

span.icon {
  & img {
    vertical-align: middle;
    margin: 0 0.2em;
    width: 1em;
    height: 1em;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-style: normal;
  text-rendering: optimizeLegibility;
  margin-top: 0;
  margin-bottom: 0.4em;
  line-height: 1.2;
}

h1 {
  font-size: $h1-font-size;
  @include media {
    font-size: $h1-font-size * $h-scale-large;
  }
}

h2 {
  font-size: $h2-font-size;
  @include media {
    font-size: $h2-font-size * $h-scale-large;
  }
  &.first {
    border-bottom: solid 3px $fourth-color;
    color: $base-color;
    margin-bottom: 1em;
    text-align: center;
    & span {
      border-bottom: solid 3px $base-color;
      display: inline-block;
      margin-bottom: -3px;
      padding-bottom: 0.4em;
    }
  }
  &.second {
    color: $base-color;
    border-bottom: 3px solid $base-color;
    padding: 0.5em 1em 0.5em 0;
    margin-bottom: 1em;
    @include media {
    font-size: $h2-font-size;
  }
  }
}

h3 {
  font-size: $h3-font-size;
  &.first{
    border-bottom: solid 4px $base-color;
    padding-bottom: 0.5em;
    margin-bottom: 1em;
  }
  &.second{
    background: rgba($third-color, 0.75);
    border-bottom: solid 3px $base-color;
    padding: 0.5em 1em;
    margin-bottom: 1em;
    border-radius: 5px  5px 0 0;
  }
  &.third {
    position: relative;
    background: $third-color;
    border: solid 2px darken($third-color, 30%);
    border-radius: 10px;
    color: #333;
    text-shadow: 0 0 2px #fff;
    margin: 0 0 1.5em 0;
    padding: 0.4em 1em;
    z-index: -4;
  }
  &.fourth {
    position: relative;
    background: $base-color;
    border-radius: 5px;
    color: #fff;
    margin: 0 0 1em 0;
    padding: 0.4em 1em;
    z-index: -4;
  }
  &.mb_narrow {
	  margin: 0 0 0.4em 0;
	}
}

h4 {
  font-size: $h4-font-size;
  &.first {
    margin-bottom: 1em;
    padding-left: 0.5em;
    border-left: 4px solid $base-color;
  }
  &.second {
    border-bottom: 1px dotted $base-color;
    color: $base-color;
    margin-bottom: 1em;
    padding-bottom: 0.4em;
  }
  &.third {
    color: $base-color;
  }
}

h5 {
  font-size: $h5-font-size;
  &.first {
    color: $base-color;
    margin-bottom: 0.6em;
  }
	&.second {
		color: rgba($base-color , 0.85);
		margin-bottom: 0.6em;
  }
}

h6 {
  font-size: $h6-font-size;
  @include media(medium) {
    font-size: $h6-font-size * $h-scale-medium;
  }
  @include media(large) {
    font-size: $h6-font-size * $h-scale-large;
  }
  &.second {
    border-bottom: 1px solid #CFD8DC;
    border-left: 1px solid #CFD8DC;
    color: #424242;
    margin-bottom: 0.6em;
    padding-bottom: 0.1em;
    padding-left: 0.4em;
  }
}

.template-title{
  font-size: 1.6em;
  background: #595959;
  padding: 0.5em 1em;
  color: #fff;
  margin-bottom: 1em;
  border-radius: 5px;
}


/* ***** code ***** */
pre {
    overflow: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
}
code,
pre {
  background-color: #fff;
  border: 1px solid #efefef;
  //border-radius: 3px;
  color: #484848;
  font-family: Consolas, monospace;
  font-size: .9375em;
  margin: 1em 0; }

pre {
  padding: 0.5em 1.5em; }

code {
  padding: 1em;
  margin: 0 0.4em;
  vertical-align: middle; }

pre code {
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  vertical-align: inherit; }

