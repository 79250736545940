@charset "UTF-8";

.clearfix:after {
  content:" ";
  display:block;
  clear:both;
}

.block-center {
	display: block;
	margin: 0 auto;
}

.ov-hidden {
  overflow: hidden;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}