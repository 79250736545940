@charset "UTF-8";

ul, ol {
  list-style: none;
  line-height: inherit;
  margin: 0;
  padding: 0;
}

// ++++++++++++++++++++ UL
ul.default {
  list-style: disc outside;
  padding-left: 1.5em;
  & ul {
    list-style: circle outside;
    padding-left: 1em;
  }
}

ul.disc {
  & li{
    position: relative;
    padding-left: 1.5em;
  }
  & li:before {
    content: '\002022';
    color: $base-color;
    position: absolute;
    left: 0.3em;
  }
}

.list-horizon {
  @include make-flex;
  -webkit-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  & li:not(:last-child) {
    padding-right: 1em;
  }
}

// ++++++++++++++++++++ OL
ol.default {
  list-style: decimal outside;
  //margin: 1em 0;
  padding-left: 1.5em;
  & ol {
    list-style: decimal outside;
    padding-left: 1em;
  }

  & > li:not(:last-child) {
    margin-bottom: 1em;
  }
}

// ++++++++++++++++++++ DL
dl {
  & dt {
    font-weight: bold;
    margin-bottom: 0.25em;
    &:not(:first-child) {
      margin-top: 1em; }
  }
}

dl.default {
  line-height: 1.6;
}

@include media(large) {
  dl.dl-horizon {
    margin: 0 auto;}
dl.dl-horizon dt,
dl.dl-horizon dd {
  line-height: 2; }
dl.dl-horizon dt {
  width: 10em;
  float: left;
  clear: left;
  margin-top: 0;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }
dl.dl-horizon dd {
  margin-left: 11.25em; }
}

dl.dl-horizon.left dt {
  text-align: left;
}

@include media(large) {
dl.dl-horizon-center {
  line-height: 2; }
dl.dl-horizon-center dt {
  width: 10em;
  float: left;
  clear: left;
  margin-top: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;}
dl.dl-horizon-center dd {
  margin-left: 10.25em;
  line-height: 2;}
dl.dl-horizon-center>.label+dt,
dl.dl-horizon-center>.label+dd {
  line-height: 2;
  padding: 0.2em 0.425em 0.125em;
  margin-bottom: 0.5em;}
}

.list-border > li + li{
  border-top: dotted 1px $gray-light;
  margin-top: 2em;
}

// 新着情報
.news-list {
  dt{
    margin-top: 0;
    color: #EF810F;
    &:not(:first-child){
      margin-top: 0;
    }
    &::before{
      content: "\f105";
      font-family: FontAwesome;
      width: 1em;
      height: 1em;
      margin-right: 0.5em;
      line-height: 1;
    }
  }
  dd{
    padding-bottom: 0.5em;
    border-bottom: 1px dotted #BAAF92;
    &+dt{
      padding-top: 0.5em;
    }
  }
}