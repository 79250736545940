@charset "UTF-8";

// icoのベース設定
@mixin ico{
  position: relative;
  padding-left: 1.5em;
  //vertical-align: middle;
  &::before{
    font-family: fontawesome;
    font-size: 1.25em;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    line-height: 1;
    //padding-top: 0.1em;
  }
  &.ico-right{
    padding-left: 0;
    padding-right: 1.75em;
    &::before{
      left: auto;
      right: 0;
      transform: translateY(-50%);
    }
  }
}

//カラー設定
.ico-white::before{
  color: #fff;
}
.ico-base::before{
  color: $base-color;
}
.ico-second::before{
  color: $second-color;
}
.ico-red::before{
  color: $color-red;
}

//info
.ico-info{
  @include ico;
  &::before{
    content: '\f05a';
  }
}

//pdf
.ico-pdf{
  @include ico;
  &::before{
    content: '\f1c1';
  }
}

//doc
.ico-doc{
  @include ico;
  &::before{
    content: '\f1c2';
  }
}

//xls
.ico-xls{
  @include ico;
  &::before{
    content: '\f1c3';
  }
}

//ppt
.ico-ppt{
  @include ico;
  &::before{
    content: '\f1c4';
  }
}

//arrow
.ico-arrow{
  @include ico;
  &::before{
    content: '\f054';
  }
}

//arrow-circle
.ico-arrow-circle{
  @include ico;
  &::before{
    content: '\f138';
  }
}

//arrow-phone
.ico-arrow-phone{
  @include ico;
  &::before{
    content: '\f095';
  }
}

//arrow-fax
.ico-arrow-fax{
  @include ico;
  &::before{
    content: '\f1ac';
  }
}

//arrow-mail
.ico-arrow-mail{
  @include ico;
  &::before{
    content: '\f003';
  }
}



