@charset "UTF-8";


.pagenation {
  @include make-flex;
  -webkit-justify-content: center;
            -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
       -ms-flex-align: center;
          align-items: center;
  text-align: center;
  
  & a {
    border: 1px solid $gray-light;
    border-radius: 3px;
    color: #333;
    display: block;
    min-width: 32px;
    padding: 0.4rem 0.8rem;
    
    &:hover {
      border: 1px solid lighten($gray-light, 15%);
      }
  }
  & .active {
    background: $base-color;
    color: #fff;
  }
  
  & span {
    color: $gray-light;
    display: block;
    margin: 0 4px;
  }
  
  & li {
    margin: 0 2px;
  }
  
  & ul {
    list-style: none;
    padding: 0;
    @include make-flex;
    -webkit-justify-content: center;
              -ms-flex-pack: center;
            justify-content: center;
    -webkit-align-items: center;
         -ms-flex-align: center;
            align-items: center;
    flex-grow: 1;
    flex-shrink: 0;
  }
}