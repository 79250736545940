@charset "UTF-8";


.cd-img-replace {
  /* replace text with a background-image */
  display: inline-block;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
}

.overflow-hidden {
  overflow: hidden;
}

// Main components

.cd-main-content {
  /* set a min-height and a z-index to be sure that the main element completely covers the lateral menu */
  min-height: 100%;
  position: relative;
  //background-color: #f5f4e9;
  z-index: 1;
//  padding-top: 50px;
  /* Force Hardware Acceleration in WebKit */
  @include menu-transition;
  margin-left: 0;
  @include media {
    margin-left: $width-menu;
  }
}
.cd-main-content.lateral-menu-is-open {
  /* translate to show the lateral menu - all content needs to be put in the .cd-main-content to translate*/
  transform: translateX($width-menu);
}


// ---------- ---------- ---------- ---------- ---------- ---------- ---------- ---------- ---------- ---------- ---------- ---------- トリガー

#cd-menu-trigger {
  position: absolute;
  left: 0;
  top: 0;
  width: $height-header*.685;
  height: $height-header*.685;
  //background-color: $second-color;
  border-right: solid 1px #fff;
  @include media {
    width: $height-header;
    height: $height-header;
  }
}
#cd-menu-trigger .cd-menu-text {
  height: 100%;
  text-transform: uppercase;
  color: #FFF;
  font-weight: 600;
  display: none;
}
#cd-menu-trigger .cd-menu-icon {
  /* this span is the central line in the menu menu */
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  bottom: auto;
  right: auto;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  width: 18px;
  height: 2px;
  background-color: #FFF;
  /* these are the upper and lower lines in the menu menu */
}
#cd-menu-trigger .cd-menu-icon::before, #cd-menu-trigger .cd-menu-icon:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: inherit;
  left: 0;
}
#cd-menu-trigger .cd-menu-icon::before {
  bottom: 5px;
}
#cd-menu-trigger .cd-menu-icon::after {
  top: 5px;
}
#cd-menu-trigger.is-clicked .cd-menu-icon {
  background-color: rgba(255, 255, 255, 0);
}
#cd-menu-trigger.is-clicked .cd-menu-icon::before, #cd-menu-trigger.is-clicked .cd-menu-icon::after {
  background-color: white;
}
#cd-menu-trigger.is-clicked .cd-menu-icon::before {
  bottom: 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
#cd-menu-trigger.is-clicked .cd-menu-icon::after {
  top: 0;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
@include media {
  #cd-menu-trigger {
    display: none;
  }
}

// ---------- ---------- ---------- ---------- ---------- ---------- ---------- ---------- ---------- ---------- ---------- ---------- nav
#cd-lateral-nav {
  border-right: solid 1px #ccc;
  position: fixed;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 2;
  width: $width-menu;
  background-color: $third-color;
  overflow-y: auto;
  @include menu-transition;
  -webkit-transform: translateX(-$width-menu);
          transform: translateX(-$width-menu);
  @include media {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }

  & a {
    display: block;
    line-height: 1.2;
    &:hover {
      text-decoration: none;
    }
  }

  & .cd-navigation { // 親の ul
    margin: 0;
    @include media {
      margin: 0;
    }

    & > li {
      border-bottom: 1px solid $gray-lighter;
      & > a {
        color: #333;
        padding: 1.2em 1em;
        position: relative;
        padding: 1.2em 1em 1.2em 2.2em;
        &:before {
          content: '';
          background: $second-color;
          width: 4px;
          height: 18.67px;
          display: block;
          position: absolute;
          top: 50%;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          left: 1em;
          border-radius: 2px;
          @include media(medium) {
            width: 6px;
            height: 28px;
          }
        }
      }
    }
  }

  & .sub-menu { // 子の ul
    display: none;
    border-bottom: none;

    & > li {
      border-top: 1px solid $gray-lighter;
      position: relative;
      &:before {
        content: '';
        background: url('/images/icon-arrow-child.svg') no-repeat center center;
        background-size: 14px 14px;
        display: block;
        width: 14px;
        height: 14px;
        position: absolute;
        left: 1.5em;
        top: 50%;
        transform: translateY(-50%);
        @include media {
          background-size: 16px 16px;
          width: 16px;
          height: 16px;
        }
      }

      & > a {
        color: #111;
        font-size: 85%;
        padding: 1.2em 1em 1.2em 3.5em;
        background: darken($third-color, 5%);
      }
    }
  }
}

#cd-lateral-nav a.current {
  background-color: #3a4a4d;
  color: #FFF;
}
.no-touch #cd-lateral-nav a:hover {
  color: #FFF;
}

#cd-lateral-nav.lateral-menu-is-open {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
  visibility: visible;
  @include menu-transition;
  /* smooth the scrolling on touch devices - webkit browsers */
  -webkit-overflow-scrolling: touch;
}

/* style menu items which have a submenu  */
#cd-lateral-nav .item-has-children {
}

#cd-lateral-nav .item-has-children > a {
  position: relative;
  text-transform: uppercase;
  //font-weight: 600;
  /* this is the right arrow to show that the item has a submenu  */
}
#cd-lateral-nav .item-has-children > a::after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 6px 0 6px;
  border-color: $gray-light transparent transparent transparent;
  position: absolute;
  right: 1em;
  top: 50%;
  transform: translateY(-50%);
  transition: border .2s;
}
#cd-lateral-nav .item-has-children > a.submenu-open::after {
  border: 1px solid $gray-light;
  width: 12px;
}


  .nav-basic { // 子の ul
    border-bottom: none;
    & > li {
      border-top: 1px solid $gray-lighter;
      position: relative;
      &:before {
        content: '';
        background: url('/images/icon-arrow-child.svg') no-repeat center center;
        background-size: 14px 14px;
        display: block;
        width: 14px;
        height: 14px;
        position: absolute;
        left: 1.5em;
        top: 50%;
        transform: translateY(-50%);
        @include media {
          background-size: 16px 16px;
          width: 16px;
          height: 16px;
        }
      }

      & > a {
        color: #111;
        font-size: 85%;
        padding: 1.2em 1em 1.2em 3.5em;
        background: darken(rgba(#ffecc9, 0.5), 10%);
      }
    }
  }
