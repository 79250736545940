@charset "UTF-8";


table {
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
	padding: 0;
	margin: 0 0 1em;
  th,td{
    padding: 0.5em;
  }
}

// スクロールを出すためのwrapper
.table-scroll {
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

table.default {
  background: #fff;
  & th,td {
    border: 1px solid #ccc;
  }
  & th {
    background: rgba($third-color, 0.75);
  }
  & td {
    padding: 0.5em;
  }
}

//th固定
table.fluid{
  background: #fff;
  & th,td {
    border: 1px solid #ccc;
  }
  & th {
    background: rgba($third-color, 0.75);
    width: 10em;
  }
  & td {
    padding: 0.5em;
  }
}

//th固定 block
table.fluid-block{
  background: #fff;
  & th,td {
    border: 1px solid #ccc;
  }
  & th {
    background: rgba($third-color, 0.75);
    display: block;
  }
  & td {
    padding: 0.5em;
    display: block;
  }
  & tr + tr th{
    border-top: none;
    border-bottom: none;
  }
  & tr:first-child th{
    border-bottom: none;
  }
  @include media(medium){
    th{
      width: 10em;
      display: table-cell;
      border: solid 1px #ccc !important;
    }
    td{
      display: table-cell;
    }
  }
}

// 職員募集
table.recruit-table {
  background: #fff;
  & th,td {
    border: 1px solid #ccc;
    display: block;
  }
  & th {
    background: rgba($third-color, 0.75);
    @include media(large) {
      width: 10em;
    }


    &:nth-child(n+1) {
      margin-top: -1px;
    }
	&.recruit_th_title {
		background:rgba($third-color, 0.75);
		padding:0.3em 0;
		border-bottom:none;
		}
  }
  & td {
    margin-top: -1px;
    padding: 0.5em;
  }

  @include media {
    & th,td {
      border: 1px solid #ccc;
      display: table-cell;
      margin-top: 0;
    }
  }
}
