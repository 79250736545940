@charset "UTF-8";

// Flex

// 横組み
.flex-box {
  @include make-flex;
  -webkit-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
}
// 縦組み
.flex-column {
  @include make-flex;
  -webkit-flex-flow: column wrap;
      -ms-flex-flow: column wrap;
          flex-flow: column wrap;
}

/* ++++++++++++++++++++++++++++++ カラムの揃え方 */

// 横組み
.align-end {
         -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
            -ms-flex-pack: end;
          justify-content: flex-end; }

.align-center {
         -webkit-box-pack: center;
  -webkit-justify-content: center;
            -ms-flex-pack: center;
          justify-content: center; }

.align-justify {
         -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
            -ms-flex-pack: justify;
          justify-content: space-between; }

.align-spaced {
  -webkit-justify-content: space-around;
            -ms-flex-pack: distribute;
          justify-content: space-around; }


// 縦組み  旧Android iOS6以前は未対応 */
.align-end-vertical {
  -webkit-align-content: flex-end;
     -ms-flex-line-pack: flex-end;
          align-content: flex-end; }

.align-center-vertical {
  -webkit-align-content: center;
     -ms-flex-line-pack: center;
          align-content: center; }

.align-justify-vertical {
  -webkit-align-content: space-between;
     -ms-flex-line-pack: justify;
          align-content: space-between; }

.align-spaced-vertical {
  -webkit-align-content: space-around;
     -ms-flex-line-pack: distribute;
          align-content: space-around; }


/* 子要素の天地の揃え align-itemsは親要素・align-selfは子要素 */
.item-top {
    -webkit-box-align: start;
  -webkit-align-items: flex-start;
       -ms-flex-align: start;
          align-items: flex-start; }
.item-top-child {
        -webkit-align-self: flex-start;
  -ms-flex-flex-item-align: start;
                align-self: flex-start; }

.item-end {
    -webkit-box-align: end;
  -webkit-align-items: flex-end;
       -ms-flex-align: end;
          align-items: flex-end; }
.item-end-child {
        -webkit-align-self: flex-end;
  -ms-flex-flex-item-align: end;
                align-self: flex-end; }

.item-center {
    -webkit-box-align: center;
  -webkit-align-items: center;
       -ms-flex-align: center;
          align-items: center; }
.item-center-child {
        -webkit-align-self: center;
  -ms-flex-flex-item-align: center;
                align-self: center; }

.item-stretch {
    -webkit-box-align: stretch;
  -webkit-align-items: stretch;
       -ms-flex-align: stretch;
          align-items: stretch; }
.item-stretch-child {
        -webkit-align-self: stretch;
  -ms-flex-flex-item-align: stretch;
                align-self: stretch; }

// ---------- ---------- ---------- ---------- ---------- ---------- ---------- ---------- order
.order-0to1 {
  order: 0;
  @include media(medium) {
    order: 1;
  }
}
.order-1to0 {
  order: 1;
  @include media(medium) {
    order: 0;
  }
}

@for $i from 0 through 11 {
	.order-#{$i} {
		order: #{$i};
	}
}