@charset "UTF-8";

header {
  background: $base-color;
  flex: none;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 10;
  height: $height-header * .685;
  padding: 6.85px 0;

  @include media(large) {
    height: $height-header;
    padding: 10px 0;
    position: inherit;
  }

  & .header-logo {
    & img {
      width: auto;
      height: 50px * .685;
      position: absolute;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      @include media(large) {
        height: 50px;
        right: 2rem;
        left: auto;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
      }
    }
  }

  &.lateral-menu-is-open {
  }
}

.phone-trigger{
  position: absolute;
    right: 0;
    top: 0;
    width: 47.95px;
    height: 47.95px;
    padding: 1rem;
    border-left: solid 1px #fff;
    span{
      background: url('/images/icon/icon-phone.svg') no-repeat;
      width: 100%;
      height: 100%;
      display: block;
    }
  @include media(large) {
    display: none;
  }
}
