@charset "UTF-8";

/* tab */
.tab-nav {
  @include make-flex;
  margin: 0;
  padding: 0;
  list-style: none;
  border-bottom: 1px solid #ddd;
  & > li {
    color: #07D;
    //margin-bottom: -1px;
    position: relative;
    transition: .3s ease-out;
    &:nth-child(n+2) {
      margin-left: .5em;
    }
    & a {
      background: $gray-lightest;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      border-bottom: 4px solid $gray-lightest;
      display: block;
      padding: .8rem 1.2rem;
      -webkit-transition: background-color 0.25s ease-out;
              transition: background-color 0.25s ease-out;
      &.current {
        //border-color: #ddd;
        border-bottom: 4px solid $base-color;
        background: #fff;
        color: #333;
      }
      &:hover {
        cursor: pointer;
        text-decoration: none;
      }
    }
  }
}
.tab-nav > li a:not(.current):hover,
.tab-nav > li a:not(.current):focus {
  background: #f5f5f5;
  border-bottom: 4px solid $base-color;
  color: #333;
  //margin-bottom: 0px;
  outline: none; }

.tabsbox {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 1rem;
}
