@charset "UTF-8";
/* CSS Document */

/* 可視と不可視 hide & hidden
---------- ---------- ---------- --------- ---------- */
.hide {
  display: none !important; }

.invisible {
  visibility: hidden; }

@media screen and (min-width: 0em) and (max-width: 47.9375rem) {
  .hide-small {
    display: none !important; }
}
@media screen and (max-width: 0rem), screen and (min-width: 48rem) {
  .show-small {
    display: none !important; }
}

@media screen and (min-width: 48rem) {
  .hide-medium {
    display: none !important; }
}
@media screen and (max-width: 47.9375rem) {
  .show-medium {
    display: none !important; }
}
@media screen and (min-width: 48rem) and (max-width: 63.9375rem) {
  .hide-medium-only {
    display: none !important; }
}
@media screen and (max-width: 47.9375rem), screen and (min-width: 64rem) {
  .show-medium-only {
    display: none !important; }
}

@media screen and (min-width: 64rem) {
  .hide-large {
    display: none !important; }
}
@media screen and (max-width: 63.9375rem) {
  .show-large {
    display: none !important; }
}
@media screen and (min-width: 64rem) and (max-width: 74.9375rem) {
  .hide-large-only {
    display: none !important; }
}
@media screen and (max-width: 63.9375rem), screen and (min-width: 75rem) {
  .show-large-only {
    display: none !important; }
}