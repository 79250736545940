@charset "UTF-8";

// Exclusive

.mCSB_inside > .mCSB_container {
  margin-right: 10px;

}

.no-pe{
  pointer-events: none;
}


.underline{
  text-decoration: underline;
}



// 2024/04/11
iframe.inline-vivaform {
  margin: 0;
  padding: 0;
  border: none;
  font-style: normal;
  text-align: left;
  width: 100%;
  height: auto;
  zoom: 1;
}
