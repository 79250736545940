@charset "UTF-8";

// Flex Mixin

@mixin make-flex {
  		display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
}

@mixin make-flex-inline {
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

@mixin flex-flow {
  -webkit-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
}

@mixin align-justify {
  -webkit-justify-content: space-between;
            -ms-flex-pack: justify;
          justify-content: space-between;
}
